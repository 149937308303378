import * as React from 'react';
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from './MenuItems';
import Contact from '../menu/Contact';
import Include from '../menu/Include';
import General from '../menu/General';
import Us from '../menu/Us';
import Country from './header/Country';
import { ICountryType, defaultICountryType } from '../interfaces/ICountryType';
import SearchBar from './header/SearchBar';
import Stack from '@mui/material/Stack';
import Home from './Home';
import Player from '../player/Player';
import MoonLoader from 'react-spinners/MoonLoader';
import { defaultIStationType, IStationType } from '../interfaces/IStationType';
import ReactHlsPlayer from "react-hls-player/dist";
import { SocialIcon } from 'react-social-icons';
import Reproductor from '../utils/Reproductor';
import Global from '../../appGlobals';

const drawerWidth: number = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(0),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const Index = () => {

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [country, setCountry] = useState<ICountryType>(defaultICountryType());
  const [open, setOpen] = React.useState(true);
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const onChangeCountry = useCallback((country:ICountryType) => {

    if(country !== null)
    {
      setCountry(country);
      localStorage.setItem('radio_libre_country', JSON.stringify(country));
    }

  }, [])

  /*
  * Station
  */
  const [stations, setStations] = useState<IStationType[]>([]);
  const [currentStation, setCurrentStation] = useState<IStationType>(defaultIStationType());

  useEffect(() => {

    const info = localStorage.getItem("radio_libre_station_player");

    if (info !== void 0 && info !== null && info !== '' && currentStation?.code <= 0)
        setCurrentStation(JSON.parse(info));
    else
        setCurrentStation(currentStation);

  },[currentStation]);
  
  /*
  * Audio
  */
  const audioRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [stationInfo, setStationInfo] = useState({
    currentTime: 0,
    duration: 0,
    animationPercentage: 0,
  });
  
  const activeLibraryHandler = (nextPrev:any) => {

    const newStation = stations.map((station:IStationType) => {
      if (station.code === nextPrev.code) {
        return {
          ...station,
          active: true,
        };
      } else {
        return {
          ...station,
          active: false,
        };
      }
    });
    setStations(newStation);
  };

  const timeUpdateHandler = (e:any) => {
    const currentTime = e.target.currentTime;
    const duration = e.target.currentTime;

    // Calculate Percentage
    const roundedCurrent = Math.round(currentTime);
    const roundedDuration = Math.round(duration);
    const animation = Math.round((roundedCurrent / roundedDuration) * 100);

    setStationInfo({
      ...stationInfo,
      currentTime,
      duration,
      animationPercentage: animation,
    });
  };

  const stationEndHandler = async () => {

    const currentIndex = stations.findIndex((station:IStationType) => station.code === currentStation.code);
    await setCurrentStation(stations[(currentIndex + 1) % stations.length]);
    activeLibraryHandler(stations[(currentIndex + 1) % stations.length]);

    if (isPlaying)
    {
      const audioPromise = audioRef.current.play();
      if (audioPromise !== undefined) {
        audioPromise.then(() => {
            audioRef.current.pause();
          })
          .catch((error:any) => {
            if(audioRef.current != null)
              audioRef.current.play()
            else
              window.location.reload()
          });
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener("resize", () => {
         setWindowWidth(window.innerWidth)
    });
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Suspense fallback={<div><MoonLoader color='black'/></div>}>
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
                backgroundColor:'#fff'
              }}
            >
              <IconButton
                edge="start"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '6px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
            <SearchBar/>
            <Box sx={{ flexGrow: 1 }} />
              <Stack direction="row" alignItems="center" spacing={1}>
                <Country onChange={onChangeCountry}/>
              </Stack>
            </Toolbar>
          </AppBar>
        </Suspense>
        
        <Suspense fallback={<div><MoonLoader color='black'/></div>}>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Typography variant="subtitle1" style={{ textAlign:'center'}}>
              Síguenos en <SocialIcon style={{width:'20px'}} network="instagram" bgColor="#424242"  url="https://www.instagram.com/radiolibreonline/?utm_source=qr&igshid=OGU0MmVlOWVjOQ%3D%3D" target='_blank' rel='noopener' />
            </Typography>
            <Divider sx={{ my: 1 }} />
            {/*secondaryListItems*/}
          </List>
        </Drawer>
        </Suspense>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <a href="https://www.instagram.com/cerosquema?igsh=dWg0anZibThxY3Fz" target="_blank" rel="noopener noreferrer"><img src={ (window.location.hostname === "localhost") ? require('../../images/logo_cero_squema.jpg') : Global.URL + 'images/logo_cero_squema.jpg'} alt="Logo" height={'100'}/></a>
            </div>
            <Suspense fallback={<div><MoonLoader color='black'/></div>}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Routes>
                      <Route path="/" element={
                        <Home 
                          country = {country}
                          audioRef={audioRef}
                          isPlaying={isPlaying}
                          setStations={setStations}
                          setCurrentStation={setCurrentStation}
                          search={false}
                        />} 
                      />
                      <Route path="/index.html" element={
                        <Home 
                          country = {country}
                          audioRef={audioRef}
                          isPlaying={isPlaying}
                          setStations={setStations}
                          setCurrentStation={setCurrentStation}
                          search={false}
                        />} 
                      />
                      <Route path="/search" element={
                        <Home 
                          country = {country}
                          audioRef={audioRef}
                          isPlaying={isPlaying}
                          setStations={setStations}
                          setCurrentStation={setCurrentStation}
                          search={true}
                        />} 
                      />
                      <Route path="/contacto" element={<Contact/>}/>
                      <Route path="/incluir_radio" element={<Include/>}/>
                      <Route path="/general" element={<General/>}/>
                      <Route path="/nosotros" element={<Us/>}/>
                      <Route path="/reproductor" element={<Reproductor/>}/>
                    </Routes>
                  </Paper>
                </Grid>
              </Grid>
            </Suspense>
          </Container>
        </Box>

        <Suspense fallback={<div><MoonLoader color='black'/></div>}>
          <AppBar position="fixed" style={{backgroundColor:'#fff'}} sx={{ top: 'auto', bottom: 0 }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                  component="h1"
                  variant="h6"
                  color="black"
                  align='center'
                  sx={{ flexDirection: 1, paddingTop: '6px' }}
                >
                <Player
                  currentStation={currentStation}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  stationInfo={stationInfo}
                  setStationInfo={setStationInfo}
                  audioRef={audioRef}
                  stations={stations}
                  setCurrentStation={setCurrentStation}
                  activeLibraryHandler={activeLibraryHandler}
                  windowWidth={windowWidth}
                /> 
              </Typography>
            </Toolbar>
          </Container>
          </AppBar>
        </Suspense>
        {(!currentStation?.url?.includes('m3u8')) &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
          <audio preload="auto"
            onTimeUpdate={timeUpdateHandler}
            onLoadedMetadata={timeUpdateHandler}
            onEnded={stationEndHandler}
            ref={audioRef}
            src={currentStation.url}
            onAbort={() => {}}
          ></audio>
        </Suspense>
      }

      {(currentStation?.url?.includes('m3u8')) &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
         <ReactHlsPlayer
            playerRef={audioRef}
            src={currentStation.url}
            onTimeUpdate={timeUpdateHandler}
            onLoadedMetadata={timeUpdateHandler}
            width="0"
            height="0"
          />
        </Suspense>
      }
      </Box>
    </ThemeProvider>
  );
};

export default Index;