import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Index from './components/main/Index';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <BrowserRouter>
        <Index/>
      </BrowserRouter>
    </div>
  );
}

export default App;
