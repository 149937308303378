import React, { useCallback, useState, useEffect } from 'react';
import Global from '../../../appGlobals';
import { ICountry } from '../../interfaces/ICountry';
import { ICountryType, defaultICountryType } from "../../interfaces/ICountryType";

import IconButton from '@mui/material/IconButton';
import { countryApi } from '../../services/countryApi';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const Country = React.memo((props: ICountry) => {
    /*
     * Country
     */
    const { data: countriesList = [] } = countryApi.useGetAllAsyncQuery();
    const [countries, setCountries] = useState<ICountryType[]>([]);
    const [country, setCountry] = useState<ICountryType>(defaultICountryType());

    const [open, setOpen] = useState(null);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const onChangeModel = useCallback((country: any) => {
        setCountry(country);
        props.onChange(country);
        setOpen(null);
    }, [props]);

    useEffect(() => {
        const getCountries = async () => {
            let countryDefault = countriesList.find(o => o.code.toString() === Global.countryCode.toString());

            setCountries(countriesList);

            const localCountry = localStorage.getItem('radio_libre_country');

            if (localCountry && localCountry !== "undefined" && localCountry.length > 2) {
                const parsedLocalCountry = JSON.parse(localCountry);

                if (parsedLocalCountry && parsedLocalCountry.code !== countryDefault?.code) {
                    countryDefault = parsedLocalCountry;
                }

                onChangeModel(countryDefault);
            } else {
                onChangeModel(countryDefault);
            }
        };

        if (countriesList.length) {
            getCountries();
        }
    }, [countriesList, onChangeModel]);

    return (
        <>
            <Tooltip title={country.es}>
                <IconButton
                    onClick={handleOpen}
                    sx={{
                        width: 40,
                        height: 40,
                    }}
                >
                    <img src={country.flag} alt={country.es} />
                </IconButton>
            </Tooltip>
            <Popover
                open={!!open}
                anchorEl={open}
                onClose={() => setOpen(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 180,
                    },
                }}
            >
                {countries.map((option) => (
                    <MenuItem
                        value={country.code}
                        key={option.code}
                        selected={option.code === country.code}
                        onClick={() => onChangeModel(option)}
                        sx={{ typography: 'body2', py: 1 }}
                    >
                        <Box component="img" alt={option.es} src={option.flag} sx={{ width: 28, mr: 2 }} />
                        {option.es}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
});

export default Country;
