import React from "react";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Contact = () => {

  return (
    <div style={{paddingTop:20,  display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
        <Container component="main" style={{marginTop:8,marginBottom:2}}>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Contáctenos
            </Typography>
            <div style={{textAlign: 'justify'}}>
                <p><strong>¿DUDAS, INQUIETUDES O SUGERENCIAS?</strong></p>
                <p>Si necesitas información, no dudes en ponerte en contacto con nosotros a través de la dirección de correo electrónico contacto@radiolibreonline.com.</p>
            </div>
        </Container>
    </div>
  );
};

export default Contact;