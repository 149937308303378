import React from "react";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Us = () => {

  return (
    <div style={{paddingTop:20,  display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
        <Container component="main" style={{marginTop:8,marginBottom:2}}>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Nosotros
            </Typography>
            <div style={{textAlign: 'justify'}}>
                <p>RadioLibreOnline es un nuevo directorio de emisoras en línea AM/FM que puedes sintonizar en cualquier dispositivo con conexión a internet. Queremos enfatizar que nunca solicitaremos información personal, y el acceso a nuestra página es completamente gratuito.</p>
                <p>Nuestro directorio se actualiza constantemente con emisoras de todo el mundo. Si no encuentras tu estación favorita, por favor avísanos enviando los datos correspondientes a través del enlace 'Incluir tu radio'.</p>
                <p>Si disfrutas de nuestro sitio, te invitamos a compartir nuestro enlace con tus amigos y familiares.</p>
            </div>
        </Container>
    </div>
  );
};

export default Us;