import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBaseQuery } from './baseApi';
import { IStationType } from '../interfaces/IStationType';


export const stationApi = createApi({
    reducerPath: 'stationApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (builder) => ({
        getById: builder.query<IStationType, {code: number}>({
            query: (arg) => `v1/station?code=${arg.code}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
        getByCountry: builder.query<IStationType[], {country: number, start: number, end:number}>({
            query: (arg) => `v1/station?country=${arg.country}&start=${arg.start}&end=${arg.end}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
        getsTopRandonmByCountry: builder.query<IStationType[], {country: number, random: number}>({
            query: (arg) => `v1/station?country=${arg.country}&random=${arg.random}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
        getsTopByCountry: builder.query<IStationType[], {country: number, top: number}>({
            query: (arg) => `v1/station?country=${arg.country}&top=${arg.top}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
        getsByCountrySearch: builder.query<IStationType[], {countryCode: number, search: string, start:number, end:number}>({
            query: (arg) => `v1/station?country=${arg.countryCode}&search=${arg.search}&start=${arg.start}&end=${arg.end}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
        getsByCountryStateSearch: builder.query<IStationType[], {countryCode: number, stateCode:string, search: string, start:number, end:number}>({
            query: (arg) => `v1/station?country=${arg.countryCode}&state=${arg.stateCode}&search=${arg.search}&start=${arg.start}&end=${arg.end}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
        setBrokenLink: builder.mutation<boolean,{action:string, station:number, email:string}>({
            query: (arg) => ({
                url: "v1/station",
                method: "POST",
                body: arg
            }),
        }),
        setView: builder.mutation<boolean,{action:string, station:number}>({
            query: (arg) => ({
                url: "v1/station",
                method: "POST",
                body: arg
            }),
        }),
    })
});