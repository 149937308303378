import React ,{ useState } from 'react';

import { stationApi } from '../services/stationApi';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { IconButton, Snackbar, Tooltip } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Icon } from '@iconify/react';

const UnLink = (props:any) => {

  const [ triggerSetBrokenLink ] = stationApi.useSetBrokenLinkMutation();

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    state:false,
    message:''
  });
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState({
    open:false,
    message:''
  });

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const alerthandleClose = () => {
    setAlert({
      open:false,
      message:''
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {

    if (email.length === 0) {
        setErrors({
          state:true,
          message:'Debe diligenciar el correo electronico'
        });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setErrors({
          state:true,
          message:'Debe diligenciar un correo electrónico valido'
        });
    }
    else{
      let result = await triggerSetBrokenLink({action:"brokenlink", station:props.station.code, email});

      setErrors({
        state:false,
        message:''
      });

      setOpen(false);

      setAlert({
          open:true,
          message:(result) ? 'Se reporto enlace roto con éxito!' : 'Error reportando enlace roto!'
        });
    }
  };

  return (
        <div>
            <Tooltip title="Enlace Roto">
              <IconButton onClick={handleClickOpen}>
                  <Icon icon="gravity-ui:link-slash" style={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"¿Experimentas problemas con la estación de radio?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Déjanos tu dirección de correo electrónico, y una vez que hayamos realizado la validación, te informaremos al respecto.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Correo Electrónico"
                    type="email"
                    fullWidth
                    variant="standard"
                    required={true}
                    error={errors.state}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <span style={{color:'red'}}>{errors.message}</span>
                </DialogContent>
                <DialogActions>
                  <Button style={{color:'black', padding: 3, borderColor:'black'}}  onClick={handleSave} autoFocus>Aceptar</Button>
                  <Button style={{color:'black', padding: 3, borderColor:'black'}}  onClick={handleClose}>Cancelar</Button>                
                </DialogActions>
            </Dialog>
            <Snackbar open={alert.open}
              autoHideDuration={6000}
              onClose={alerthandleClose}
            >
              <Alert onClose={alerthandleClose} severity="success" sx={{ width: '100%' }}>
                {alert.message}
              </Alert>
            </Snackbar>
        </div>
  );
};

export default UnLink;
