import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Global from '../../appGlobals';
import { createBcryptHash } from '../utils/Helpers';

import axios from 'axios';


const getToken = async () => {

    try {
          const hashedPassword = await createBcryptHash(Global.password);

          const response = await axios
          .post(Global.API + Global.urlAccessToken, JSON.stringify({
              "username": Global.username,
              "password": hashedPassword
          }));

          if (response.status === 200) {
            return response.data;
          }
          else {
            throw response.data;
          }
      } catch (ex) {

        throw ex;
      }
  };

export const customFetchBaseQuery = () => {
    return fetchBaseQuery({
      baseUrl:Global.API,
      prepareHeaders: async (headers) => {
        if(Global.env === "Prod") {
         const token = await getToken(); // Obtén el token
         headers.set('Authorization', `Bearer ${token}`); // Agrega el token al encabezado
        }
        return headers;
      },
    });
};