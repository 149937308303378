const Global = () => { }

Global.var = 100;
Global.env = "Prod";
Global.countryCode = 170;

Global.topStations = 21;
Global.start = 30;

Global.username = "juanluis.quintero@gmail.com";
Global.password = "mi_clave_secreta";
Global.secretKeyPW = '0123456789abcdef0123456789abcdef';
Global.urlAccessToken = "v1/access";

Global.URL = (Global.env === "Prod") ? 'https://www.radiolibreonline.com/' : 'https://www.radiolibreonline.com/';
Global.API = (Global.env === "Prod") ? 'https://www.radiolibreonline.com/api/' : 'https://www.radiolibreonline.com/test/api/';

Global.TimeZone = 'America/Bogota';
Global.IntervalNewView = (60 * 60 * 1000);
Global.Version = "Versión 2.0"
export default Global;