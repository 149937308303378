import * as React from 'react';
import { useEffect, useState, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Global from '../../appGlobals';
import Station from '../player/Station';
import { IStationType, defaultIStationType } from '../interfaces/IStationType';
import { stationApi } from '../services/stationApi';
import Typography from '@mui/material/Typography';
import { MoonLoader } from 'react-spinners'
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import { Icon } from '@iconify/react';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchStations: React.FC<any> = (props) => {
  const query = useQuery(); 
  const searchText = query.get("searchText") || '';

  const [triggerGetsByCountrySearch, { isLoading: stationSearchListLoading, isSuccess: stationSearchListSuccess, }] = stationApi.useLazyGetsByCountrySearchQuery();
  const [stations, setStations] = useState<IStationType[]>([defaultIStationType()]);
  const [stationResult, setStationResult] = useState<IStationType[]>([]);
  const [startSearch, setStartSearch] = useState(0);
  const [loadSearch, setLoadSearch] = useState(false);

  function removeAccentMarks(cadena:string) {
    return cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const onSearch = async (type: string = '') => {
    let start = 0;

    if (type === 'concat') {
      start = startSearch + Global.start;
      setStartSearch(startSearch + Global.start);
    }

    const result = await triggerGetsByCountrySearch({
      countryCode: props.country.code,
      search: removeAccentMarks(searchText),
      start,
      end: Global.start
    });

    setStationResult(result.data || []);

    if (start === 0) {
      setStations(result.data || []);
      props.setStations(result.data || []);
    } else {
      setStations(stations.concat(result.data || []));
      props.setStations(stations.concat(result.data || []));
    }

    setLoadSearch(false);
  };

  const loadhandleClick = () => {
    setLoadSearch(true);
    onSearch('concat');
  };

  useEffect(() => {
    onSearch();
  }, [props.country, searchText]);
  
  return (
    <div>
      <Suspense fallback={<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><MoonLoader color='black'/></div>}>
        <Typography component="h6" variant="h6" align="left" color="textPrimary" gutterBottom>
          Resultado Búsqueda
        </Typography>
        <Grid container spacing={2} className="song-container">
          {stationSearchListLoading && <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Tooltip title={'Cargar más estaciones'}>
            <IconButton >
              <Icon icon={'eos-icons:loading'} style={{ color: 'black' }}  />
            </IconButton>
          </Tooltip></div>
          }
          {stationSearchListSuccess && stations?.filter(o => o.code > 0).map(station => (
            <Station
              key={station.code}
              station={station}
              setStations={props.setStations}
              stations={stations}
              setCurrentStation={props.setCurrentStation}
              code={station.code}
              audioRef={props.audioRef}
              isPlaying={props.isPlaying}
            />
          ))}
        </Grid>
        {stationResult.length > 0 && stationResult.length >= Global.start && <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Tooltip title={'Cargar más estaciones'}>
          <IconButton onClick={loadhandleClick}>
            <Icon icon={!loadSearch ? 'pajamas:scroll-down' : 'eos-icons:loading'} style={{ color: 'black' }}  />
          </IconButton>
        </Tooltip></div>
        }
      </Suspense>
      <br /><br />
    </div>
  );
};


export default SearchStations;
