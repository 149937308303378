import { ICityType, defaultICityType } from "./ICityType";
import { ICountryType, defaultICountryType } from "./ICountryType";
import { IStateType, defaultIStateType } from "./IStateType";

export interface IStationType {
    code: number,
    name: string,
    url: string,
    homepage: string,
    logo: string,
    tag: string,
    country: number,
    state: string,
    city: string,
    language: string,
    frequency: string,
    like: number,
    unlike: number,
    active: boolean,
    selected: boolean,
    color: string[],
    date: Date,
    Country: ICountryType,
    State: IStateType,
    City: ICityType,
}

export function defaultIStationType(): IStationType {
    return {
        code: 0,
        name: '',
        url: '',
        homepage: '',
        logo: '',
        tag: '',
        country: 0,
        state: '',
        city: '',
        language: '',
        frequency: '',
        like: 0,
        unlike: 0,
        active: false,
        selected: false,
        color: [],
        date: new Date(0),
        Country: defaultICountryType(),
        State: defaultIStateType(),
        City: defaultICityType(),
    };
}