import React from "react";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const General = () => {

  return (
    <div style={{paddingTop:20, display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
        <Container component="main" style={{marginTop:8,marginBottom:2}}>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Condiciones Generales
            </Typography>
              <div style={{
                        overflow: "hidden",
                        textAlign: "justify"
                      }}>
                <p>Las presentes Condiciones Generales regulan el uso del sitio web <strong>www.radiolibreonline.com</strong>.</p>
                <p>La navegación por nuestro sitio web le atribuye la condición de Usuario de la misma e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en estas Condiciones Generales de Uso, por lo que, si el Usuario no está de acuerdo con cualquiera de las condiciones aquí establecidas, no deberá usar su Sitio Web.</p>
                <p><strong>RadioLibreOnline</strong>, se reserva el derecho de modificar en cualquier momento las presentes Condiciones Generales de Uso, así como cualesquiera otras condiciones generales o particulares, reglamentos de uso, instrucciones o avisos que resulten de aplicación. Dichas modificaciones serán publicadas por <strong>RadioLibreOnline</strong>, en su Sitio Web y el Usuario asumirá la responsabilidad de revisar las Condiciones Generales de Uso que sean aplicables cada vez que ingrese a la página.</p>
                <p>El Usuario será responsable de hacer una utilización correcta des Sitio Web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y las presentes Condiciones Generales de Uso. El Usuario responderá frente a <strong>RadioLibreOnline</strong> y a terceros, de los daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.</p>
                <p><strong>1. Condiciones de acceso y utilización</strong></p>
                <p>Las presentes Condiciones Generales regulan el uso del sitio web <strong>www.radiolibreonline.com</strong>.</p>
                <p>El Sitios web radiolibreonline.com y sus servicios informativos son de libre acceso y gratuitos
                El Usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios ofrecidos por <strong>RadioLibreOnline</strong> y a no emplearlos para:</p>
                <ul>
                  <li><strong>a)</strong> Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, discriminatorios, peyorativos, que atenten contra la dignidad de la Institución, de apología del terrorismo o, en general, contrarios a la ley o al orden público;</li>
                  <li><strong>b)</strong> Introducir en los Sitios Webs virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de <strong>RadioLibreOnline</strong> o de terceras personas; así como obstaculizar el acceso de otros Usuarios a los portales y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales RadioLibreOnline presta sus servicios por medios tradicionales o electrónicos (en línea);</li>
                  <li><strong>c)</strong> Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de <strong>RadioLibreOnline</strong> o de terceros;</li>
                  <li><strong>d)</strong> Suplantar la identidad de otro Usuario o de un tercero;</li>
                  <li><strong>e)</strong> Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.</li>
                </ul>
                <p><strong>Responsabilidad y exclusión de garantías</strong><br></br>
                <p>El contenido de nuestro portal de internet es de carácter general, brinda información sobre los servicios que presta <strong>RadioLibreOnline</strong> y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su vigencia o actualidad, idoneidad o utilidad para un objetivo específico.</p>
                </p>
                <p><strong>RadioLibreOnline</strong> no será responsable por los daños y perjuicios que pudieran ocasionarse mediante el uso de la información provista por medio del servicio, salvo que dicha información haya sido brindada manifiestamente con ánimo de dañar al Usuario.</p>
                <p>Debido a que en la actualidad los medios técnicos disponibles no permiten garantizar la absoluta falta de injerencia de la acción de terceras personas en las páginas publicadas en Internet, <strong>RadioLibreOnline</strong> no garantiza la exactitud o veracidad de todo o parte de la información contenida en su Sitio Web, ni su actualización, ni que dicha información haya sido alterada o modificada en todo o en parte, luego de haber sido incluida en cualquiera de sus Sitios Web, ni cualquier otro aspecto o característica de la información brindada por medio de la página o a través de los enlaces eventualmente incluidos en el mismo.</p>
                <p><strong>RadioLibreOnline</strong> excluye, hasta donde permite la legislación aplicable en la República de Colombia, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:</p>
                <ul>
                  <li><strong>a)</strong> La imposibilidad de acceso a los Sitios Web de **RadioLibreOnline**  o la falta de veracidad, exactitud y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición a los que se haya accedido a través de los Sitios Web o de los servicios que se ofrecen de manera tradicional o por medios electrónicos (en línea).</li>
                  <li><strong>b)</strong> La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los Usuarios.</li>
                  <li><strong>c)</strong> El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y las presentes Condiciones Generales de Uso como consecuencia del uso incorrecto de las Páginas Web.</li>
                </ul>
                <p>El Usuario acepta defender, indemnizar y eximir de toda responsabilidad a <strong>RadioLibreOnline</strong>, sus empleados y/o sus filiales (los "Indemnizados") frente a cualquier reclamación, gasto, responsabilidad, pérdida, coste y daño, incluidos los honorarios de los abogados, en el que incurran los Indemnizados (i) en relación con un uso de las Páginas Web con hipervínculos en la página que no esté permitido o que no se ajuste a los fines perseguidos por <strong>RadioLibreOnline</strong> o (ii) que se derive del contenido que el Usuario ha proporcionado.</p>
                <p><strong>3. Política de Privacidad de los datos personales</strong></p>
                <p><strong>RadioLibreOnline</strong> da cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y conforme a la POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES que se encuentra implementada por la compañía y publicada en la plataforma virtual, la cual puede ser consultada y solicitada mediante el correo electrónico <strong>contacto@radiolibreonline.com</strong>.</p>
                <p><strong>RadioLibreOnline</strong>, con ocasión de un trámite o servicio desarrollado a través de medios electrónicos, podrá solicitar información a los Usuarios de sus Sitios Web. La solicitud de información se hace siempre para que el Usuario la suministre de manera voluntaria. Se entiende por información personal aquella brindada por el Usuario para el registro y que incluye datos como nombre, identificación, edad, género, dirección, correo electrónico y teléfono. Los datos que se reciban a través de los formularios de su Sitio Web serán incorporados a una base de datos de la cual es responsable <strong>RadioLibreOnline</strong>. Esta Sociedad tratará los datos de forma confidencial y exclusivamente con la finalidad de ofrecer los servicios solicitados, con todas las garantías legales y de seguridad que impone la Constitución Política, las normas aplicables a la protección de datos de carácter personal y demás normas concordantes.</p>
                <p><strong>RadioLibreOnline</strong> se compromete a no ceder, vender, ni compartir los datos recibidos en sus portales con terceros, sin la aprobación expresa de los usuarios. Las filiales de <strong>RadioLibreOnline</strong> no se considerarán terceros para estos efectos. Asimismo, <strong>RadioLibreOnline</strong> cancelará o rectificará los datos cuando resulten inexactos, incompletos o hayan dejado de ser necesarios o pertinentes para su finalidad.</p>
                <p><strong>RadioLibreOnline</strong> ha adoptado los niveles de seguridad de protección de los datos personales legalmente requeridos, con la instalación de medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos facilitados.</p>
                <p><strong>RadioLibreOnline</strong> podrá utilizar cookies durante la prestación de servicios en sus portales de Internet. Las cookies son archivos de información personal que se alojan en el computador del Usuario, quien tiene la posibilidad de configurar su programa navegador de manera que se impida la creación de archivos cookie o se advierta de la misma.</p>
                <p>Para ser un Usuario de nuestro Sitio Web NO es obligatorio completar un formulario de registro. El acceso a nuestros contenidos es libre y gratuito. NO recopilamos datos de los usuarios como condición para navegar en nuestros sitios.<br></br>
                <p>Ante cualquier duda sobre el uso de datos por parte de <strong>RadioLibreOnline</strong>, puede contactarse con el responsable del Tratamiento de Datos al correo <strong>contacto@radiolibreonline.com</strong>.</p>
                Respetando la Política de Privacidad colombiana, como cliente o usuario de <strong>RadioLibreOnline</strong> podrá eliminar o dar de baja sus datos personales enviando un correo a <strong>contacto@radiolibreonline.com</strong> describiendo dicha solicitud. <strong>RadioLibreOnline</strong> se compromete a eliminar los datos lo más pronto posible y en un plazo máximo de 15 días.</p>
                <p><strong>4. Derechos de propiedad intelectual</strong></p>
                <p>La página web <strong>RadioLibreOnline</strong> y el contenido incluido en las mismas son propiedad exclusiva de <strong>RadioLibreOnline</strong> y están protegidos por las normas jurídicas de derechos de autor de la República de Colombia y la Comunidad Andina de Naciones, así como por las disposiciones de tratados internacionales.</p>
                <p>La compilación, organización y publicación del contenido, así como el software y las invenciones utilizadas en su Sitio Web son propiedad exclusiva de <strong>RadioLibreOnline</strong> y/o de sus filiales.</p>
                <p>Por lo tanto, todos los contenidos de las Páginas Web tales como textos, fotografías, gráficos, imágenes, iconos, software, entre otros, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad es exclusiva de <strong>RadioLibreOnline</strong>.</p>
                <p>El Usuario reconoce y acepta que todos los derechos de propiedad intelectual sobre los contenidos y/o cualesquiera otros elementos insertados en su Sitio Web pertenecen a <strong>RadioLibreOnline</strong> y/o a sus filiales. En ningún caso el acceso a su Sitio Web implica algún tipo de renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos, salvo que se establezca expresamente lo contrario. Las presentes Condiciones Generales de Uso de su Sitio Web no confieren a los Usuarios ningún otro derecho de utilización, alteración, explotación, reproducción, distribución o comunicación pública de las Páginas Web y/o de sus contenidos distintos de los aquí expresamente previstos. Cualquier otro uso o explotación de tales derechos estará sujeto(a) a la previa y expresa autorización específicamente otorgada a tal efecto por <strong>RadioLibreOnline</strong> o del titular de los derechos afectados. <strong>RadioLibreOnline</strong> autoriza a los Usuarios a utilizar, visualizar, imprimir, descargar y almacenar los contenidos gratuitos y/o elementos insertados en las Páginas Web; siempre y cuando sea para su propio uso. Autoriza igualmente las citas que utilicen contenidos gratuitos y/o elementos insertados en su Sitio Web, pero en todo caso deberá indicarse el origen y/o autor de los mismos. Queda terminantemente prohibida la utilización de tales elementos, su reproducción, comunicación y/o distribución con fines comerciales o lucrativos, así como su modificación, alteración o traducción, y en general todo uso que resulte contrario a los derechos reconocidos a favor de los autores por las normas aplicables.</p>
                <p>Sí mismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en su Sitio Web son propiedad exclusiva de <strong>RadioLibreOnline</strong> y/o de sus filiales, sin que pueda entenderse que el uso o acceso al mismo atribuya al Usuario derecho alguno sobre aquéllos. El Usuario se obliga a no utilizar las marcas de <strong>RadioLibreOnline</strong> de ningún modo que pueda causar confusión entre los clientes o que pueda menospreciar o desacreditar a sus filiales ni en conexión con ningún servicio o producto que no haya sido patrocinado, aprobado o producido por <strong>RadioLibreOnline</strong>. El resto de marcas comerciales que no sean propiedad de <strong>RadioLibreOnline</strong> o de sus filiales que aparezcan en esta página pertenecen a sus respectivos propietarios.</p>
                <p>El Usuario deberá denunciar cualquier violación a las Condiciones Generales de Uso de la o las Páginas Web por parte de otros Usuarios, para lo cual debe enviar un correo electrónico a <strong>contacto@radiolibre.com</strong> con el fin de que <strong>RadioLibreOnline</strong> tome todas las medidas respectivas que estén a su alcance en relación con la utilización de las Páginas Web.</p>
                <p><strong>5. Datos recolectados a través de RadioLibreOnline</strong></p>
                <p><strong>RadioLibreOnline</strong> recogerá datos del usuario a través del uso de los servicios de <strong>WWW.RADIOLIBREONLINE.COM</strong>.</p>
                <p>Estos datos estarán vinculados a la dirección IP desde la que accede a los servicios de <strong>RadioLibreOnline</strong> o a un código de usuario asignado de forma unilateral por <strong>RadioLibreOnline</strong>. Dichos datos podrán ser considerados como datos personales en la medida en que permitan identificarle o hacerle identificable.</p>
                <p>De esta forma, <strong>RadioLibreOnline</strong> podrá vincular a dichos datos la siguiente información:</p>
                <ul>
                  <li>Datos de uso y navegación: A efectos publicitarios y estadísticos, de control del uso de nuestras páginas, del acceso a servicios de terceros a través de servicios <strong>RadioLibreOnline</strong> y de mejorar el conocimiento sobre los intereses del usuario, se recogerán datos de uso y navegación relativos acerca de qué servicio utiliza el usuario, como lo utiliza, cuando, qué tipo de usuario es, o si accede a un anuncio publicitario mostrado en cualquiera de los servicios de <strong>RadioLibreOnline</strong>.</li>
                  <li>Intereses y el perfil del usuario inferidos de la navegación o uso que haga de los servicios, así como información sobre el dispositivo de acceso, el modelo de equipo, la versión del sistema operativo, los identificadores únicos del dispositivo o de la conexión, datos sobre la red móvil, así como actividad del sistema, ajustes del hardware, tipo de navegador, idioma del navegador, URL de referencia, cookies y otros dispositivos de almacenamiento y recuperación de datos, tal como se explica en la política de cookies, que permiten identificar el navegador y dispositivo del usuario, así como hacer estudios analíticos y mostrar información personalizada en función del resultado de dichos estudios, tanto en servicios propios como ajenos.</li>
                  <li>Los servicios de <strong>RadioLibreOnline</strong> también pueden ofrecer enlaces a servicios de terceros que estén marcados mediante un código para que dichos terceros puedan reconocer que ese usuario procede de un servicio de <strong>RadioLibreOnline</strong>.</li>
                  <li>Información sobre el territorio desde el que se origina la conexión a los servicios de <strong>RadioLibreOnline</strong>, para personalizar dichos servicios y en su caso, verificar la disponibilidad de los derechos de visualización de determinados contenidos audiovisuales.</li>
                </ul>
                <p><strong>6. Información y páginas web de terceros</strong></p>
                <p>Los Sitios Web de <strong>RadioLibreOnline</strong> pueden ofrecer hipervínculos o accesos a páginas web y contenidos de otras personas o entidades. <strong>RadioLibreOnline</strong> no controla, refrenda ni garantiza el contenido incluido en dichas páginas. El Usuario acepta que <strong>RadioLibreOnline</strong> no es responsable de ningún contenido, enlace asociado, recurso o servicio relacionado con la página de un tercero. Asimismo, el Usuario acepta que *<strong>RadioLibreOnline</strong> no será responsable de ninguna pérdida o daño de cualquier tipo que se derive del uso que se realice de los contenidos de un tercero. Los enlaces y el acceso a páginas web se proporcionan exclusivamente para comodidad del Usuario.</p>
                <p>El establecimiento de un vínculo con su Sitio Web de otra empresa, entidad o programa no implica necesariamente la existencia de relaciones entre <strong>RadioLibreOnline</strong> y el propietario de su Sitio Web vinculada, ni la aceptación o aprobación por parte de <strong>RadioLibreOnline</strong> de sus contenidos o servicios. Aquellas personas que se propongan establecer un vínculo se asegurarán de que el mismo únicamente permita el acceso al inicio de su Sitio Web, así mismo deberán abstenerse de realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre <strong>RadioLibreOnline</strong> y/o sus filiales o incluir contenidos ilícitos, o contrarios a las buenas costumbres y al orden público.</p>
                <p>Así mismo, <strong>RadioLibreOnline</strong> no se hace responsable respecto a la información que se halle fuera de esta Página Web y no sea gestionada directamente por el administrador de las páginas de <strong>RadioLibreOnline</strong>. Los vínculos que aparecen en las Páginas Web tienen como propósito informar al Usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrecen las Páginas Web, o que guardan relación con aquéllos. <strong>RadioLibreOnline</strong> no garantiza ni se responsabiliza del funcionamiento o accesibilidad de las páginas web vinculadas; ni sugiere, invita o recomienda la visita a las mismas, por lo que tampoco será responsable del resultado obtenido.</p>
                <p><strong>7. Acciones en caso de incumplimiento</strong></p>
                <p><strong>RadioLibreOnline</strong> se reserva el derecho a ejercer todas las acciones judiciales y/o administrativas que se encuentren a su disposición para exigir las responsabilidades que se deriven del incumplimiento por parte de un Usuario, de cualquiera de las disposiciones de estas Condiciones Generales de Uso de los Sitio Web de <strong>RadioLibreOnline</strong>.</p>
                <p><strong>8. Duración y terminación</strong></p>
                <p>La prestación del servicio del Sito Web y de los demás servicios tiene, en principio, una duración indefinida; no obstante, <strong>RadioLibreOnline</strong> está autorizada para dar por terminada o suspender la prestación del servicio de su Sitio Web y/o de cualquiera de los servicios en cualquier momento, sin perjuicio de lo que se hubiere dispuesto al respecto en las correspondientes Condiciones Generales de Uso. Cuando ello sea razonablemente posible, <strong>RadioLibreOnline</strong> advertirá previamente la terminación o suspensión de la prestación del servicio de la Página Web y de los demás servicios.</p>
                <p><strong>9. Ley aplicable y jurisdicción</strong></p>
                <p>La prestación del servicio de su Sitio Web y las presentes Condiciones Generales de Uso de la o las Páginas Web y las particulares que se llegaren a establecer se rigen por la ley colombiana.</p>
                <p>Las páginas en Internet, su diseño y presentación gráfica están amparadas por las disposiciones de la Ley 23 de 1982, artículos 12 y 30 y la Decisión 351 del Acuerdo de Cartagena (para el caso colombiano) y por las disposiciones del copyright Act de 1976 de Estados Unidos de América. En caso de controversias derivadas de los términos y condiciones incluidos en este documento que no se resuelvan de manera amigable en un término de 15 días hábiles contados a partir de la primera reclamación de alguna de las partes, el Usuario e <strong>RadioLibreOnline</strong> aceptan someterse a la justicia ordinaria.</p>
                <p><strong>10. Notificaciones</strong></p>
                <p>Todas las notificaciones y comunicaciones entre el Usuario e <strong>RadioLibreOnline</strong> se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal o electrónico. El Usuario deberá dirigirse mediante:</p>
                <ul>
                  <li>Envío por correo postal a la siguiente dirección: <strong>contacto@radiolibreonline.com</strong>.</li>
                  <li>Envío de solicitudes virtuales, a través de nuestro Canal de Contáctenos en cualquiera de nuestras filiales <strong>WWW.RADIOLIBREONLINE.COM</strong>.</li>
                </ul>
                <p>Los datos que <strong>RadioLibreOnline</strong> recolecta de terceros, se procesan y usan de conformidad con las regulaciones actuales de protección de información y privacidad, antes mencionadas.</p>
                <p><strong>11. Contáctenos</strong></p>
                <p>Si el usuario desea hacer sugerencias a <strong>RadioLibreOnline</strong> para mejorar contenidos de sus filiales, información, quejas, reclamos o preguntar sobre alguno de los servicios que ofrece puede llamar en Bogotá al 3502445243, o escribir a <strong>contacto@radiolibreonline.com</strong>.</p><br></br>
              </div>
        </Container>
    </div>
  );
};

export default General;