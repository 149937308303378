import React from "react";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import {  Tooltip } from "@mui/material";
import { IStations } from "../interfaces/IStations";

const Station = (props:IStations) => {
  const selectStationHandler = async () => {
    await props.setCurrentStation(props.station);
    await props.setStations(props.stations);
  };
  
  return (
    <div style={{paddingTop:10}}>
    <Card sx={{ boxShadow:1, height:120, marginInlineStart:1}}>
    <Tooltip title={props.station.name} style={{cursor:"pointer"}}>
        <Box sx={{ bgcolor: '#fff', color: '#000', p: 1 }}>
         <div style={{fontSize:9, textAlign: 'center', alignItems: 'center', justifyContent:"space-between"}}>
            <img alt={props.station.name}
                 src={'./' + props.station.logo}
                 height={'66'}
                 width={'96'}
                 onClick={selectStationHandler}>
            </img>
             <Box component="div" style={{height:20}}>
             {(props.station.name.length > 20) ? props.station.name.substring(0, 15) + "..." : props.station.name}
             <br></br>
             {props.station.frequency}
             </Box>
          </div>
        </Box>
      </Tooltip>
    </Card>
    </div>
  );
};

export default Station;