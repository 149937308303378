import '../../styles/player.css';
import '../../styles/fluent-ytm-chromium.css';
import { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import React from 'react';
import { IPlayer } from '../interfaces/IPlayer';
import { defaultIStationType, IStationType } from '../interfaces/IStationType';
import Global from '../../appGlobals';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import { Icon } from '@iconify/react';
import { prominent } from 'color.js';
import { stationApi } from '../services/stationApi';
import UnLink from '../main/UnLink';

const Player = React.memo((props:IPlayer) => {

    var currentStation = Object.assign({}, props.currentStation);

    const [ triggerSetView ] = stationApi.useSetViewMutation();
    const [triggerGetById] = stationApi.useLazyGetByIdQuery();
  
    const dataColor:string[] = [];
    const [colors, setColors] = useState(dataColor);
    
    const stationsPlayer:any = localStorage.getItem('radio_libre_top_stations_player');
    const [stations, setStations] = useState<IStationType[]>(JSON.parse(stationsPlayer));

    const stationsPlayerLike:any = localStorage.getItem('radio_libre_station_player_like');
    const [stationsLike, setStationsLike] = useState<IStationType[]>(JSON.parse(stationsPlayerLike));

    const stationsPlayerUnLike:any = localStorage.getItem('radio_libre_station_player_unlike');
    const [stationsUnLike, setStationsUnLike] = useState<IStationType[]>(JSON.parse(stationsPlayerUnLike));

    const [stationPosition, setStationPosition] = useState(0);

    useEffect(() => {
        setStationPosition(props.stations?.findIndex(item => item.code === props.currentStation.code) || 0);
    }, [props.currentStation, props.stations]);

    const linearGradient = {
        background: `linear-gradient(to right,
                                     ${colors[0]},
                                     ${colors[1]},
                                     ${colors[2]},
                                     ${colors[3]},
                                     ${colors[4]},
                                     ${colors[5]})`,
    };
 
    const validateStations = (station:IStationType, length:number, type: string) => {

      if(type === "top")
      {
        if (!stations?.find(item => item.code === station.code))
        {
          if(stations === undefined || stations === null)
          {
            setStations([station]);
          }
          else
          if(stations?.length < length)
          {
            setStations(prevItems => [...prevItems, station]);
          }
          else
          {
            setStations(prevItems => prevItems.slice(1));
            setStations(prevItems => [...prevItems.slice(0), station]);
          }
        }
        else{
          const index = stations.findIndex(item => item.code === station.code);
          let newStations = [...stations];
          newStations[index] = {...newStations[index], date: station.date}
          setStations(newStations);
        }
      }
      else if(type === "like")
        {
          if (!stationsLike?.find(item => item.code === station.code))
          {
            if(stationsLike === undefined || stationsLike === null)
            {
              setStationsLike([station]);
            }
            else
            if(stationsLike?.length < length)
            {
              setStationsLike(prevItems => [...prevItems, station]);
            }
            else
            {
              setStationsLike(prevItems => prevItems.slice(1));
              setStationsLike(prevItems => [...prevItems.slice(0), station]);
            }
          }
          else{
            const index = stationsLike.findIndex(item => item.code === station.code);
            let newStations = [...stationsLike];
            newStations[index] = {...newStations[index], date: station.date}
            setStationsLike(newStations);
          }
       }
       else if(type === "unlike")
        {
          if (!stationsUnLike?.find(item => item.code === station.code))
          {
            if(stationsUnLike === undefined || stationsUnLike === null)
            {
              setStationsUnLike([station]);
            }
            else
            if(stationsUnLike?.length < length)
            {
              setStationsUnLike(prevItems => [...prevItems, station]);
            }
            else
            {
              setStationsUnLike(prevItems => prevItems.slice(1));
              setStationsUnLike(prevItems => [...prevItems.slice(0), station]);
            }
          }
          else{
            const index = stationsUnLike.findIndex(item => item.code === station.code);
            let newStations = [...stationsUnLike];
            newStations[index] = {...newStations[index], date: station.date}
            setStationsUnLike(newStations);
          }
       }
    };
    
    const validateView = async (station: IStationType) => {

      const stationsPlayer:any = localStorage.getItem('radio_libre_top_stations_player');
      const storedPlayer = localStorage.getItem('radio_libre_station_player');
      const lastPlayer: IStationType = storedPlayer ? JSON.parse(storedPlayer) : defaultIStationType();
      const lastDate = new Date(lastPlayer.date?.toLocaleString('es-CO', { timeZone: Global.TimeZone }));
  
      if(stationsPlayer === null) {
          await triggerSetView({action:"view", station:station.code});
      }
      else{
          const searchStation = stations?.find(item => item.code === station.code);
  
          if(searchStation === undefined)
            await triggerSetView({action:"view", station:station.code});
          else
          if(station.name === lastPlayer.name) {
            if(station.date > new Date(lastDate.getTime() + Global.IntervalNewView))
              {
                await triggerSetView({action:"view", station:station.code});
                station.date = new Date();
              }
          }
      }
    };

    const likeStation = async () => {

      if(!stationsLike?.find(item => item.code === props.currentStation.code))
      {
        await triggerSetView({action:"like", station:currentStation.code});

        const resultStation = await triggerGetById({
          code: props.currentStation.code
        });

        let result =  resultStation.data || defaultIStationType();
        let stationTemp = currentStation;
        stationTemp.like = result.like;

        let station: IStationType[] = [defaultIStationType()];
        const stationsPlayer: string | null = localStorage.getItem('radio_libre_top_stations_player');
  
        if (stationsPlayer && stationsPlayer.length > 0) {
          station = JSON.parse(stationsPlayer);
          station = station.filter(o => o.code !== props.currentStation.code);
          validateStations(stationTemp, 10, "top");
          localStorage.setItem("radio_libre_top_stations_player",JSON.stringify(station));
        }

        localStorage.setItem("radio_libre_station_player_like",JSON.stringify(stationTemp));
        validateStations(currentStation, 50, "like");
      }
    }

    const calculateLike = () => {
      return NumberFormatter(
        (stationsLike?.filter(o => o.code === props.currentStation.code)[0] !== undefined) ? 
        stationsLike?.filter(o => o.code === props.currentStation.code)[0].like : props.currentStation.like);
    }

    const unlikeStation = async () => {

      if(!stationsUnLike?.find(item => item.code === props.currentStation.code))
      {
        await triggerSetView({action:"unlike", station:currentStation.code});

        const resultStation = await triggerGetById({
          code: props.currentStation.code
        });

        let result =  resultStation.data || defaultIStationType();
        let stationTemp = currentStation;
        stationTemp.unlike = result.unlike;

        localStorage.setItem("radio_libre_station_player_unlike",JSON.stringify(stationTemp));
        validateStations(currentStation, 50, "unlike");
      }
    }

    const calculateUnLike = () => {
      return NumberFormatter(
        (stationsLike?.filter(o => o.code === props.currentStation.code)[0] !== undefined) ? 
        stationsLike?.filter(o => o.code === props.currentStation.code)[0].unlike : props.currentStation.unlike);
    }

    const NumberFormatter = (number: number) => { 
      const formatNumber = (number: number) => 
        { 
          if (number >= 1000 && number < 1000000) 
            { 
              return (number / 1000).toFixed(1) + 'k'; 
            } 
            else if (number >= 1000000 && number < 1000000000) 
            { 
              return (number / 1000000).toFixed(1) + 'M'; 
            } 
            else if (number >= 1000000000) 
            { 
              return (number / 1000000000).toFixed(1) + 'B'; 
            } 
            else 
            { 
              return number.toString(); 
            } 
        }; 
        
      return ( <span>{formatNumber(number)}</span> );
    }

    const skipBack = () => {

      if(props.stations?.length > 0)
        {
          if(stationPosition - 1 < 0)
          {
            setStationPosition(props.stations?.length - 1);
            props.setCurrentStation(props.stations[props.stations?.length - 1]);
          }
          else{
            setStationPosition(stationPosition - 1);
            props.setCurrentStation(props.stations[stationPosition - 1]);
         }
        }

    }

    const skipForward = () => {

      if(props.stations?.length > 0)
      {
        if(stationPosition + 1 < props.stations?.length)
        {
          setStationPosition(stationPosition + 1);
          props.setCurrentStation(props.stations[stationPosition + 1]);
        }
        else{
          setStationPosition(0);
          props.setCurrentStation(props.stations[0]);
       }
      }
    }

    const playStationHandler = () => {
        try
        {
          if (props.isPlaying) {
            props.audioRef.current.pause();
            props.setIsPlaying(!props.isPlaying);
            props.audioRef.current.volume = 1;
            currentStation.date = new Date();
            localStorage.setItem("radio_libre_station_player",JSON.stringify(currentStation));
            validateStations(currentStation, 10, "top");
          }
          else {
            const audioPromise = props.audioRef.current.play();

            if (audioPromise !== undefined) {
              audioPromise.then(() => {
                  props.audioRef.current.pause();
                })
                .catch((error:any) => {
                  props.audioRef.current.play()
                });
            }
    
            props.audioRef.current.muted = props.isMuted;
            props.setIsPlaying(!props.isPlaying);
    
            currentStation.date = new Date();
            localStorage.setItem("radio_libre_station_player",JSON.stringify(props.currentStation));

            validateStations(currentStation, 10, "top");
            validateView(currentStation);
          }
        }
        catch(error)
        {
          alert(error);
        }
    };

    const volumenStationHandler = () => {
        try
        {
            props.setIsMuted(!props.isMuted);
            props.audioRef.current.muted = !props.isMuted;
            props.audioRef.current.volume = 1;
        }
        catch(error)
        {
          alert(error);
        }
    };

    const calculateDuration = (sec: number) => {
        const minutes = Math.floor(sec / 60)
        const newMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
        const seconds = Math.floor(sec % 60)
        const newSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`

        return `${newMinutes}:${newSeconds}`
    }

    const dragHandler = (e:any) => {

      props.audioRef.current.currentTime = e.target.value;
      props.setStationInfo({
        ...props.stationInfo,
        currentTime: e.target.value,
      });
    };

    useEffect(() => {

      if(stations?.length > 0) {
        localStorage.setItem('radio_libre_top_stations_player', JSON.stringify(stations));
      }
  
    }, [stations]);

    useEffect(() => {

      if(stationsLike?.length > 0) {
        localStorage.setItem('radio_libre_station_player_like', JSON.stringify(stationsLike));
      }
  
    }, [stationsLike]);

    useEffect(() => {

      if(stationsUnLike?.length > 0) {
        localStorage.setItem('radio_libre_station_player_unlike', JSON.stringify(stationsUnLike));
      }
  
    }, [stationsUnLike]);

    useEffect(() => {
        const playStation = async () => {
    
          var currentStation = Object.assign({}, props.currentStation);
    
          if (props.isPlaying) {
            currentStation.date = new Date();
            localStorage.setItem("radio_libre_station_player",JSON.stringify(currentStation));
            validateStations(currentStation, 10, "top"); 
            validateView(currentStation);
          }

          if(currentStation.logo !== "")
            {
              prominent(currentStation.logo, {
                format: 'hex',
                amount: 6,
              }).then(async (color)=>
              {
                if(currentStation !== undefined) {
            
                  currentStation.color = ["","","","","",""];
            
                  for(var  i = 0; i< color.length; i++) {
                    currentStation.color[i] = color[i].toString();
                  }
            
                  setColors(currentStation.color);
                }
              }).catch((error) => { // Manejar el error de carga de la imagen 
                console.error('Error al cargar la imagen: ', currentStation.logo); 
              });
            }
        };
    
        playStation();
    
    }, [props.isPlaying, props.audioRef, props.currentStation]);
    
    if (props.isPlaying && props.audioRef.current !== undefined) {
        props.audioRef.current.play();
        props.audioRef.current.muted = props.isMuted;
    }
    
    return (
      <Box className='playerDiv'>
        <div style={linearGradient} className="track">
            <input
              type="range"
              min="0"
              max={props.stationInfo.duration || 0}
              value={props.stationInfo.currentTime}
              onChange={dragHandler}
            />
            <div style={linearGradient} className="animate-track">
            </div>
        </div>
        <Box className='musicDivPlayer'>
        {props.currentStation.code > 0 &&
            <Box className='music' key={1}>
                <Box className='buttons'>
                    <Tooltip title={'Anterior'}>
                      <IconButton onClick={skipBack}>
                          <Icon icon={'ic:round-keyboard-double-arrow-left'} style={{ color: 'black' }}  />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={props.isPlaying ? 'Detener' : 'Reproducir'}>
                      <IconButton onClick={playStationHandler}>
                          <Icon icon={props.isPlaying ? 'ic:baseline-stop-circle' : 'ic:sharp-play-circle-filled'} style={{ color: 'black' }}  />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Siguiente'}>
                      <IconButton onClick={skipForward}>
                          <Icon icon={'ic:round-keyboard-double-arrow-right'} style={{ color: 'black' }}  />
                      </IconButton>
                    </Tooltip>
                </Box>
                <Box className='inicialSearch players'>
                    
                        {props.windowWidth > 600 && <>
                          <UnLink station={props.currentStation}/>
                        </>}

                        <p><img alt={props.currentStation.name} src={`./${props.currentStation.logo}`} /></p>
                        
                        {props.windowWidth > 600 &&
                          <Tooltip title={'Página web'}>
                            <a href={props.currentStation.homepage} target="blank" >
                              <span>{props.currentStation.name},({props.currentStation.City.es},{props.currentStation.State.es})</span>
                            </a>
                          </Tooltip>
                        }
                        {props.windowWidth > 400 && props.windowWidth <= 600 &&
                          <Tooltip title={'Página web'}>
                            <a href={props.currentStation.homepage} target="blank">
                              <span>{props.currentStation.name.substring(0, 22)}</span><br></br>
                            </a>
                          </Tooltip>
                        }
                        {props.windowWidth <= 400 &&
                          <Tooltip title={'Página web'}>
                            <a href={props.currentStation.homepage} target="blank">
                              <span>{props.currentStation.name.substring(0, 15)}</span><br></br>
                            </a>
                          </Tooltip>
                        }
                        {props.windowWidth > 600 && <>
                          <Tooltip title={'Me Gusta'}>
                            <IconButton onClick={likeStation}>
                              <Icon icon={'subway:like'} style={{ color: 'black' }}  />
                            </IconButton>
                          </Tooltip><span style={{fontSize:'16px'}}>{calculateLike()}</span>
                        </>}
                        {props.windowWidth > 600 && <>
                          <Tooltip title={'No me Gusta'}>
                            <IconButton onClick={unlikeStation}>
                              <Icon icon={'subway:unlike'} style={{ color: 'black' }} />
                            </IconButton>
                          </Tooltip><span style={{fontSize:'16px'}}>{calculateUnLike()}</span>
                        </>}
                    
                </Box>
                <div className='test'>
                    <Tooltip title={'Volumen'}>
                      <IconButton onClick={volumenStationHandler}>
                        <Icon icon={!props.isMuted ? 'ci:volume-max' : 'ci:volume-off'} style={{ color: 'black' }}  />
                      </IconButton>
                    </Tooltip>
                    <p className='PcurrentTime'>
                        {calculateDuration(props.stationInfo.duration)}
                    </p>
                </div>
            </Box>
        } 
        </Box>
      </Box>
    );
});

export default Player;
export const MemoizedPlayer = React.memo(Player);