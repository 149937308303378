//import bcrypt from 'bcryptjs';
import bcrypt from "bcryptjs-react"

export const createBcryptHash = async (password:string) => {
  const saltRounds = 10;
  const salt = bcrypt.genSaltSync(saltRounds);
  const hashedPassword = bcrypt.hashSync(password, salt);

  return hashedPassword;
};

