import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import ContactsIcon from '@mui/icons-material/Contacts';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RadioIcon from '@mui/icons-material/Radio';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from 'react-router-dom';
import Global from '../../appGlobals';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
       <img alt="Logo" src={ (window.location.hostname === "localhost") ? require('../../images/iconoradio.png') : Global.URL + 'images/iconoradio.png'} height={'60'}/>
      </ListItemIcon>
      <ListItemText>
        RadioLibreOnline V2.0
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/'} className='listItem'>
          Principal
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/nosotros'} className='listItem'>
          Nosotros
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <ContactsIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/contacto'} className='listItem'>
          Contáctenos
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <ManageAccountsIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/general'} className='listItem'>
          Condiciones Generales
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <RadioIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/incluir_radio'} className='listItem'>
          Incluir tu radio
        </Link>
      </ListItemText>
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/contacto'} className='listItem'>
          Contáctenos
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/incluir_radio'} className='listItem'>
          Incluir tu radio
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/general'} className='listItem'>
          Condiciones Generales
        </Link>
      </ListItemText>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText>
        <Link to={'/nosotros'} className='listItem'>
          Nosotros
        </Link>
      </ListItemText>
    </ListItemButton>
  </React.Fragment>
);