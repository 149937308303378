import * as React from 'react';

import TopStations from './TopStations';
import SearchStations from './SearchStations';
import { Suspense } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';

export default function Home(props:any) {

  return (
    <div>
      <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
      {!props.search &&
        <TopStations
          stations={props.stations}
          country={props.country}
          audioRef={props.audioRef}
          isPlaying={props.isPlaying}
          setStations={props.setStations}
          setCurrentStation={props.setCurrentStation} 
        />
      }
      {props.search &&
        <SearchStations
          stations={props.stations}
          country={props.country}
          audioRef={props.audioRef}
          isPlaying={props.isPlaying}
          setStations={props.setStations}
          setCurrentStation={props.setCurrentStation}
        />
      }
      <Grid>
          <Typography variant="body1" style={{color: 'black', textAlign:'justify', paddingTop:'8px'}}>
            RadioLibreOnline es un nuevo directorio de emisoras AM/FM en línea que podrás sintonizar en cualquier dispositivo con acceso a internet.
          </Typography>
      </Grid>
      <div style={{paddingTop:'32px'}}></div>
      </Suspense>
    </div>
  );
}