import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';

import Global from '../../appGlobals';
import Station from '../player/Station';
import { IStationType, defaultIStationType } from '../interfaces/IStationType';
import { stationApi } from '../services/stationApi';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import { Icon } from '@iconify/react';

const TopStations = (props: any) => {
  const random: number = Global.topStations;
  const country: number = props.country ? props.country.code : Global.countryCode;

  const { data: stationTopList = [defaultIStationType()], isLoading:stationTopListLoading, isSuccess: stationTopListSuccess } = stationApi.useGetsTopRandonmByCountryQuery({ country, random });
  const [stations, setStations] = useState<IStationType[]>([defaultIStationType()]);

  const removeDuplicates = useCallback((array: IStationType[]) => {
    const uniqueObjects: IStationType[] = [];
    const propertySet = new Set();

    for (const obj of array) {
      const propertyValue = obj.code;

      if (!propertySet.has(propertyValue)) {
        propertySet.add(propertyValue);
        uniqueObjects.push(obj);
      }
    }

    return uniqueObjects;
  }, []);

  useEffect(() => {
    let isMounted = true;

    const getTopStation = () => {
      let station: IStationType[] = [defaultIStationType()];
      const stationsPlayer: string | null = localStorage.getItem('radio_libre_top_stations_player');

      if (stationsPlayer && stationsPlayer.length > 2) {
        station = JSON.parse(stationsPlayer).filter((o: IStationType) => o.country === props.country.code);
      }

      if (isMounted) {
        const combinedStations = removeDuplicates(station.concat(stationTopList));
        setStations(combinedStations.slice(0, Global.topStations));
        props.setStations(combinedStations.slice(0, Global.topStations));
      }
    };

    if (props.country && stationTopListSuccess) {
      getTopStation();
    }

    return () => {
      isMounted = false;
    };
  }, [props.country, stationTopList, stationTopListSuccess, removeDuplicates]);

  return (
    <div>
      <Typography component="h6" variant="h6" align="left" color="textPrimary" gutterBottom>
        Top Estaciones
      </Typography>
      <Grid container spacing={2} className='song-container'>
        {stationTopListLoading && <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Tooltip title={'Cargar más estaciones'}>
            <IconButton >
              <Icon icon={'eos-icons:loading'} style={{ color: 'black' }}  />
            </IconButton>
          </Tooltip></div>
          }
        {stationTopListSuccess && stations.filter(o => o.code > 0).map(station => (
          <Station 
          key={station.code}
            station={station}
            setStations={props.setStations}
            stations={stations}
            setCurrentStation={props.setCurrentStation}
            code={station.code}
            audioRef={props.audioRef}
            isPlaying={props.isPlaying}
          />
        ))}
      </Grid>
    </div>
  );
};

export default TopStations;
